import React from 'react';
import './aboutSection.scss';

function AboutSection() {
  return (
    <section className='aboutSection'>
        <div className='aboutSection-content'>
            <h2 className='h2-small'>Discover <strong>RTS</strong></h2>
            <h2 className='h2-large'>Expert Masonry Protection in Queenstown</h2>
            <div className='about-image'></div>
            <p><strong className='font-bold'> Welcome to RTS Construction Chemicals</strong>, Queenstown's leading provider of masonry solutions, including anti-graffiti coatings and waterproofing systems. Dedicated to safeguarding your structures with innovative, eco-friendly technologies, our team specializes in delivering tailored sealing solutions that ensure durability and aesthetic appeal. We commit to excellence, prioritizing your satisfaction through services designed to secure and beautify your investments efficiently. </p>


        </div>
    </section>  
  )
}

export default AboutSection